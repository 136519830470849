html {
    height: 100%;
}

body {
    background-color: #eee;
    height: inherit;
    overflow-y: hidden;
}

#app {
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
